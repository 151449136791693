export class LocationSelectedEvent extends CustomEvent {
    constructor(eventData) {
        super('location-selected', {
            detail: {
                location: eventData
            }
        });
    }
}
/**
 * Extract address components from google result
 */
export const extractAddress = function (result) {
    let website = '';
    let name = '';
    try {
        if ("website" in result) {
            website = result?.website ? result.website : '';
        }
    }
    catch (e) {
        console.warn("Error retrieving the website", e);
    }
    try {
        if ("name" in result) {
            name = result?.name ? result.name : '';
        }
    }
    catch (e) {
        console.warn("Error retrieving the name", e);
    }
    const formattedAddress = {
        lat: result?.geometry?.location?.lat() || 0.0,
        lng: result?.geometry?.location?.lng() || 0.0,
        formattedAddress: result?.formatted_address || '',
        name: name,
        placeId: result?.place_id,
        streetNumber: result?.address_components ?
            extractComponent(result.address_components, 'street_number') :
            '',
        route: result?.address_components ?
            extractComponent(result.address_components, 'route', true) :
            '',
        locality: result?.address_components ?
            extractComponent(result.address_components, 'locality') :
            '',
        state: result?.address_components ?
            extractComponent(result.address_components, 'administrative_area_level_1') :
            '',
        country: result?.address_components ?
            extractComponent(result.address_components, 'country', true) :
            '',
        postalCode: result?.address_components ?
            extractComponent(result.address_components, 'postal_code') :
            '',
        postalCodeSufix: result?.address_components ?
            extractComponent(result.address_components, 'postal_code_suffix') :
            '',
        address2: result?.address_components ?
            extractComponent(result.address_components, 'subpremise') :
            '',
        website: website,
        isPointOfInterest: result?.types?.includes('point_of_interest') || false,
    };
    return formattedAddress;
};
function extractComponent(addressComponents, type, shortVariant = false) {
    for (const part of addressComponents) {
        if (part.types.includes(type)) {
            return shortVariant ? part.short_name : part.long_name;
        }
    }
    return '';
}
