import { defineComponent as _defineComponent } from 'vue';
import { vModelDynamic as _vModelDynamic, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "location-autocomplete" };
const _hoisted_2 = ["id", "type", "name", "placeholder", "required"];
const _hoisted_3 = {
    key: 2,
    type: "hidden",
    value: "1",
    name: "is_current_location"
};
const _hoisted_4 = { class: "location-autocomplete__results" };
const _hoisted_5 = { class: "autocomplete-list" };
const _hoisted_6 = ["onMousedown", "onMouseover"];
const _hoisted_7 = { class: "autocomplete-list__item__text" };
import { computed, onMounted, reactive, ref, toRaw, watch } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { useDebounceFn } from '@vueuse/core';
import { readPageData } from '@ts/Util/page';
import { getQueryParam } from "@ts/Util/http";
import { toPascalCase } from '@ts/Util/text';
import { LocationSelectedEvent } from "@ts/Util/google_maps";
export default /*@__PURE__*/ _defineComponent({
    __name: 'GoogleMapsSearchInput',
    props: {
        t: {
            type: Object,
            default: () => ({
                searchClassesPlaceholderText: 'Enter City, State, or Zip',
                currentLocationText: 'Current Location'
            })
        },
        enableCurrentLocation: { type: Boolean, default: false },
        submitSelector: { type: String, default: '' },
        formSelector: { type: String, default: '' },
        searchInputClass: { type: String, default: 'fac-search-input' },
        disableSubmit: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        searchInputName: { type: String, default: 'search' },
        include: { type: (Array), default: [] },
        fromQuery: { type: Boolean, default: false },
        searchInputValue: { type: String, default: null },
        searchInputId: { type: String, default: null },
        placeholder: { type: String, default: null },
        updateSearchInputValueOnSelection: { type: Boolean, default: true },
        searchInputType: { type: String, default: 'search' },
        /**
         * @see {@link https://developers.google.com/maps/documentation/javascript/place-types}
         */
        predictionTypes: { type: (Array), default: [] }
    },
    emits: [
        'location-selected',
        'update:search-input-value',
        'change',
    ],
    setup(__props, { emit: __emit }) {
        const _window = window;
        const googlePlacesKey = readPageData('googlePlaces');
        const submitButtonRef = ref(null);
        const formRef = ref(null);
        const searchInputRef = ref(null);
        const resultsWidth = ref('0');
        let sessionToken;
        let isCurrentLocation = false;
        let isCurrentLocationEnabled = false;
        let willTriggerSubmitOnEnter = false;
        const props = __props;
        const emit = __emit;
        const state = reactive({
            predictions: [],
            wantVisibility: false,
            isFocused: false,
            isVisible: computed(() => state.wantVisibility && state.isFocused),
            selectedLocation: props.searchInputValue,
            selectedLat: null,
            selectedLng: null,
            selectedFormattedAddress: null,
            selectedStreet: null,
            selectedStreetNumber: null,
            selectedCity: null,
            selectedStateIso: null,
            selectedState: null,
            selectedCountryIso: null,
            selectedPostalCode: null,
            selectedPlaceId: null,
            formattedAddress: null,
        });
        watch(() => props.searchInputValue, (newValue) => state.selectedLocation = newValue);
        const calculatedPlaceholder = computed(() => {
            return props.placeholder === null ?
                props.t.searchClassesPlaceholderText : props.placeholder;
        });
        const loadGoogleAutocomplete = () => {
            const loader = new Loader({
                apiKey: googlePlacesKey,
            });
            loader.importLibrary('places')
                .then(() => {
                refreshGoogleSessionToken();
            });
        };
        onMounted(async () => {
            isCurrentLocationEnabled = !!navigator.geolocation;
            if (props.submitSelector !== '') {
                submitButtonRef.value = document.querySelector(props.submitSelector);
            }
            if (props.formSelector !== '') {
                formRef.value = document.querySelector(props.formSelector);
            }
            if (isCurrentLocationEnabled && props.enableCurrentLocation) {
                state.predictions.push({
                    description: props.t.currentLocationText,
                    isCurrentLocation: true,
                    isHighlighted: false
                });
            }
            if (props.fromQuery) {
                props.include.forEach(field => {
                    state[`selected${toPascalCase(field)}`] = getQueryParam(field);
                });
                state.selectedLocation = getQueryParam(props.searchInputName);
            }
        });
        const shouldInclude = (inputName) => props.include.includes(inputName);
        const onMouseOver = (location) => {
            clearHighlight();
            location.isHighlighted = true;
        };
        const onMouseLeave = () => {
            clearHighlight();
        };
        const onKeyEnter = (event) => {
            event.preventDefault();
            if (willTriggerSubmitOnEnter) {
                formRef.value.submit();
            }
            let [location] = state.predictions.filter(location => location.isHighlighted === true);
            if (location) {
                selectLocation(location);
            }
        };
        const clearHighlight = () => {
            let selectedIndex = -1;
            state.predictions.forEach((loc, index) => {
                if (loc.isHighlighted) {
                    loc.isHighlighted = false;
                    selectedIndex = index;
                }
            });
            return selectedIndex;
        };
        const moveHighlight = (direction) => {
            let selectedIndex = clearHighlight();
            if (selectedIndex > -1) {
                selectedIndex = selectedIndex === 0 && direction < 0 ? state.predictions.length - 1 :
                    (selectedIndex + direction) % state.predictions.length;
            }
            else {
                selectedIndex = direction > 0 ? 0 : state.predictions.length - 1;
            }
            if (state.predictions.length === 0) {
                return null;
            }
            let result = state.predictions[selectedIndex];
            result.isHighlighted = true;
            return result;
        };
        const handleInput = (event) => {
            emit('update:search-input-value', event.target.value);
            handlePredictions(event);
        };
        const handlePredictions = useDebounceFn((event) => {
            if (!event.target.value) {
                return;
            }
            const request = {
                input: event.target.value,
                sessionToken: sessionToken,
            };
            if (props.predictionTypes.length > 0) {
                request.includedPrimaryTypes = toRaw(props.predictionTypes);
            }
            google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(request)
                .then(res => setPredictions(res.suggestions))
                .catch(err => {
                console.error("GoogleMapsSearchInput: Place predictions error: ");
                console.error(err);
            });
        }, 500);
        const setPredictions = (predictions) => {
            willTriggerSubmitOnEnter = false;
            let newPredictions = [];
            if (isCurrentLocationEnabled && props.enableCurrentLocation) {
                newPredictions.push({
                    description: props.t.currentLocationText,
                    isCurrentLocation: true,
                    isHighlighted: false
                });
            }
            for (const prediction of predictions) {
                newPredictions.push({
                    description: prediction.placePrediction?.text.text ?? '',
                    place: prediction.placePrediction?.toPlace(),
                    isCurrentLocation: false,
                    isHighlighted: false
                });
            }
            state.predictions = newPredictions;
            resultsWidth.value = searchInputRef.value.offsetWidth;
            setVisibilityDesired(predictions.length !== 0);
        };
        const setVisibilityDesired = (wantVisibility) => {
            resultsWidth.value = searchInputRef.value.offsetWidth;
            state.wantVisibility = wantVisibility;
        };
        const setFocused = (focused) => {
            loadGoogleAutocomplete();
            state.isFocused = focused;
        };
        function disableSubmitButton() {
            if (submitButtonRef.value !== null) {
                submitButtonRef.value.disabled = true;
            }
        }
        function enableSubmitButton() {
            if (submitButtonRef.value !== null) {
                submitButtonRef.value.disabled = false;
            }
        }
        const selectLocation = (location) => {
            willTriggerSubmitOnEnter = !props.disableSubmit;
            if (props.updateSearchInputValueOnSelection) {
                state.selectedLocation = location.description;
            }
            isCurrentLocation = location.isCurrentLocation;
            disableSubmitButton();
            if (location.isCurrentLocation) {
                handleSelectCurrentLocation();
            }
            else {
                handleSelectPredictedLocation(location);
            }
            setVisibilityDesired(false);
        };
        const handleSelectCurrentLocation = () => {
            navigator.geolocation.getCurrentPosition(function (position) {
                state.selectedLat = position.coords.latitude;
                state.selectedLng = position.coords.longitude;
                enableSubmitButton();
            }, function () {
                isCurrentLocationEnabled = false;
            });
        };
        function populateSelectedFields(place) {
            const components = {
                selectedLat: place.location?.lat() ?? null,
                selectedLng: place.location?.lng() ?? null,
                selectedFormattedAddress: place.formattedAddress ?? null,
                selectedPlaceId: place.id,
                formattedAddress: place.formattedAddress ?? null,
                selectedStreet: null,
                selectedStreetNumber: null,
                selectedCity: null,
                selectedStateIso: null,
                selectedCountryIso: null,
                selectedPostalCode: null,
                selectedState: null,
            };
            const addressComponents = place.addressComponents || [];
            addressComponents.forEach(item => {
                switch (item.types[0]) {
                    case 'street_number':
                        components.selectedStreetNumber = item.longText;
                        break;
                    case 'route':
                        components.selectedStreet = item.longText;
                        break;
                    case 'administrative_area_level_2':
                        if (!Boolean(components.selectedCity)) {
                            components.selectedCity = item.longText;
                        }
                        break;
                    case 'administrative_area_level_1':
                        components.selectedStateIso = item.shortText;
                        components.selectedState = item.longText;
                        break;
                    case 'country':
                        components.selectedCountryIso = item.shortText;
                        break;
                    case 'postal_code':
                        components.selectedPostalCode = item.longText;
                        break;
                    case 'locality':
                        if (item.types.length > 1 && item.types.includes('political')) {
                            components.selectedCity = item.longText;
                        }
                        break;
                }
            });
            for (const key in components) {
                state[key] = components[key];
            }
        }
        const handleSelectPredictedLocation = (location) => {
            let placeDetails = location.place?.fetchFields({
                fields: ['addressComponents', 'formattedAddress', 'location', 'id'],
            });
            placeDetails?.then(place => {
                refreshGoogleSessionToken();
                populateSelectedFields(place.place);
                document.dispatchEvent(new LocationSelectedEvent(state));
                const eventData = {
                    location: state.selectedLocation,
                    lat: state.selectedLat,
                    lng: state.selectedLng,
                    formattedAddress: state.selectedFormattedAddress,
                    street: state.selectedStreet,
                    streetNumber: state.selectedStreetNumber,
                    city: state.selectedCity,
                    stateIso: state.selectedStateIso,
                    stateName: state.selectedState,
                    countryIso: state.selectedCountryIso,
                    postalCode: state.selectedPostalCode,
                    placeId: state.selectedPlaceId,
                };
                emit('location-selected', eventData);
                enableSubmitButton();
            });
        };
        async function refreshGoogleSessionToken() {
            sessionToken = new google.maps.places.AutocompleteSessionToken();
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
                    id: props.searchInputId,
                    ref_key: "searchInputRef",
                    ref: searchInputRef,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((state.selectedLocation) = $event)),
                    type: props.searchInputType,
                    name: props.searchInputName,
                    class: props.searchInputClass,
                    placeholder: calculatedPlaceholder.value,
                    autocomplete: "off",
                    required: props.required,
                    onInput: _cache[1] || (_cache[1] = ($event) => (handleInput($event))),
                    onChange: _cache[2] || (_cache[2] = ($event) => (emit('change', $event))),
                    onFocus: _cache[3] || (_cache[3] = ($event) => (setFocused(true))),
                    onBlur: _cache[4] || (_cache[4] = ($event) => (setFocused(false))),
                    onKeyup: [
                        _cache[5] || (_cache[5] = _withKeys(($event) => (moveHighlight(1)), ["down"])),
                        _cache[6] || (_cache[6] = _withKeys(($event) => (moveHighlight(-1)), ["up"]))
                    ],
                    onKeydown: _cache[7] || (_cache[7] = _withKeys(($event) => (onKeyEnter($event)), ["enter"]))
                }), null, 16, _hoisted_2), [
                    [_vModelDynamic, state.selectedLocation]
                ]),
                _renderSlot(_ctx.$slots, "default"),
                (shouldInclude('lat'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 0,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((state.selectedLat) = $event)),
                        type: "hidden",
                        name: "lat"
                    }, null, 512)), [
                        [_vModelText, state.selectedLat]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('lng'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 1,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((state.selectedLng) = $event)),
                        type: "hidden",
                        name: "lng"
                    }, null, 512)), [
                        [_vModelText, state.selectedLng]
                    ])
                    : _createCommentVNode("", true),
                (props.enableCurrentLocation && _unref(isCurrentLocation))
                    ? (_openBlock(), _createElementBlock("input", _hoisted_3))
                    : _createCommentVNode("", true),
                (shouldInclude('street'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 3,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((state.selectedStreet) = $event)),
                        type: "hidden",
                        name: "street"
                    }, null, 512)), [
                        [_vModelText, state.selectedStreet]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('street_number'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 4,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((state.selectedStreetNumber) = $event)),
                        type: "hidden",
                        name: "street_number"
                    }, null, 512)), [
                        [_vModelText, state.selectedStreetNumber]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('city'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 5,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event) => ((state.selectedCity) = $event)),
                        type: "hidden",
                        name: "city"
                    }, null, 512)), [
                        [_vModelText, state.selectedCity]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('state_iso'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 6,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event) => ((state.selectedStateIso) = $event)),
                        type: "hidden",
                        name: "state_iso"
                    }, null, 512)), [
                        [_vModelText, state.selectedStateIso]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('country_iso'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 7,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event) => ((state.selectedCountryIso) = $event)),
                        type: "hidden",
                        name: "country_iso"
                    }, null, 512)), [
                        [_vModelText, state.selectedCountryIso]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('postal_code'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 8,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event) => ((state.selectedPostalCode) = $event)),
                        type: "hidden",
                        name: "postal_code"
                    }, null, 512)), [
                        [_vModelText, state.selectedPostalCode]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('place_id'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 9,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event) => ((state.selectedPlaceId) = $event)),
                        type: "hidden",
                        name: "place_id"
                    }, null, 512)), [
                        [_vModelText, state.selectedPlaceId]
                    ])
                    : _createCommentVNode("", true),
                (shouldInclude('formatted_address'))
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 10,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event) => ((state.formattedAddress) = $event)),
                        type: "hidden",
                        name: "formatted_address"
                    }, null, 512)), [
                        [_vModelText, state.formattedAddress]
                    ])
                    : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("div", {
                    class: "location-autocomplete__results",
                    style: _normalizeStyle({ width: resultsWidth.value + 'px' })
                }, [
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("ul", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.predictions, (location) => {
                                return (_openBlock(), _createElementBlock("li", {
                                    key: location.description,
                                    class: _normalizeClass(["autocomplete-list__item", {
                                            'autocomplete-list__item--is-highlighted': location.isHighlighted,
                                            'location-autocomplete__item--is-current-location': location.isCurrentLocation
                                        }]),
                                    onMousedown: ($event) => (selectLocation(location)),
                                    onMouseover: ($event) => (onMouseOver(location)),
                                    onMouseleave: _cache[18] || (_cache[18] = ($event) => (onMouseLeave()))
                                }, [
                                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "location-autocomplete__item__icon" }, null, -1)),
                                    _createElementVNode("div", _hoisted_7, _toDisplayString(location.description), 1)
                                ], 42, _hoisted_6));
                            }), 128))
                        ]),
                        _cache[20] || (_cache[20] = _createElementVNode("picture", null, [
                            _createElementVNode("source", {
                                srcset: "/img/blt/vendor/powered_by_google_on_white_hdpi.webp",
                                type: "image/webp"
                            }),
                            _createElementVNode("img", {
                                class: "location-autocomplete__results__vendor",
                                src: "/img/blt/vendor/powered_by_google_on_white_hdpi.png",
                                width: "100",
                                alt: "Powered by Google"
                            })
                        ], -1))
                    ])
                ], 4), [
                    [_vShow, state.predictions.length !== 0 && state.isVisible]
                ])
            ]));
        };
    }
});
